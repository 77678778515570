import styled from "styled-components";
import { theme } from "../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Price = styled.span``;

export const Discount = styled.span`
    background-color: ${theme.colors.primaryRed};
`;

export const LineThrough = styled.span`
    text-decoration: line-through;
`;
