import styled from "styled-components";
import { getDirection } from "../../../../themes/common";
import { theme } from "../theme/toyotaTheme";

const NumberedDisclaimerIcon = styled.span<{ isLight?: boolean; hasMousePointer?: boolean }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin: 0;
    margin-${getDirection("right")}: ${theme.space[1]}px;
    font-family: ${theme.fonts.regular};
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: ${({ isLight }) => (isLight ? theme.colors.primaryWhite : theme.colors.grey4)};
    border: 1px solid ${({ isLight }) => (isLight ? theme.colors.primaryWhite : theme.colors.grey4)};
    border-radius: 50%;
    cursor: ${({ hasMousePointer }) => (hasMousePointer ? "pointer" : "default")};

    &:first-child {
        margin-${getDirection("left")}: 0;
    }

    &:last-child {
        margin-${getDirection("right")}: 0;
    }
`;

export default NumberedDisclaimerIcon;
