import * as React from "react";
import CompareTable from "../../common/CompareTable";
import { CompareUSCLabel, CompareUSCLabelType } from "../../../utils/labels";
import * as CompareEntry from "../styles/CompareEntry";
import { IconWrapper } from "../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import Icon from "../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { UsedParsedSpecEcoValueType, UsedCompareSpec } from "../../../utils/usedSpecsUtils";
import EfficiencyClassLabel from "../../../../shared-components/toyota/efficiency-class/EfficiencyClassLabel";

export type UsedQuickSpecType = {
    title: string;
    type: UsedCompareSpec;
    values: (string | number | UsedParsedSpecEcoValueType)[];
    carNotLoaded?: boolean;
    isMessageLabel?: boolean;
    suffix?: string;
    hasSmallFontSize?: boolean;
};

const UsedQuickSpecRow = (props: UsedQuickSpecType): JSX.Element => {
    const { title, values, isMessageLabel, carNotLoaded, suffix, hasSmallFontSize, type } = props;

    const getContentBySpecType = (
        usedType: UsedCompareSpec,
        value: string | number | UsedParsedSpecEcoValueType,
    ): JSX.Element | string | number => {
        if (usedType === UsedCompareSpec.Distance) {
            return <CompareUSCLabel label="usedCompareSpecDistanceValue" params={{ value: value as string }} />;
        }
        if (usedType === UsedCompareSpec.EfficiencyClass) {
            const { letter, eco } = value as UsedParsedSpecEcoValueType;
            return <EfficiencyClassLabel ecoInfo={eco} code={letter} />;
        }
        return (
            (value as string) || (
                <IconWrapper iconColor="grey4">
                    <Icon variant="minus" />
                </IconWrapper>
            )
        );
    };

    return (
        <CompareTable
            items={values}
            columnChild={(value) => (
                <CompareEntry.Wrapper>
                    <CompareEntry.Title>
                        {isMessageLabel ? <CompareUSCLabel label={title as CompareUSCLabelType} /> : title}
                    </CompareEntry.Title>
                    <CompareEntry.ValueWrapper>
                        <CompareEntry.KeyValue hasSmallFontSize={hasSmallFontSize}>
                            <CompareEntry.Value isLoading={!!carNotLoaded}>
                                {getContentBySpecType(type, value)}
                            </CompareEntry.Value>
                            {!carNotLoaded && suffix && <CompareEntry.Suffix>{suffix}</CompareEntry.Suffix>}
                        </CompareEntry.KeyValue>
                    </CompareEntry.ValueWrapper>
                </CompareEntry.Wrapper>
            )}
        />
    );
};

export default UsedQuickSpecRow;
