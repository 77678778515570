import React from "react";
import { useDispatch } from "react-redux";
import PdfLink from "./PdfLink";
import CompareTable from "../common/CompareTable";
import { CompareUSCDispatchType, useCompareUSCSelector } from "../../redux/store";
import { CompareUSCLabel } from "../../utils/labels";
import { getUscVehicleUrlInfoFromCompareResult } from "../../utils/usedSpecsUtils";
import { getUscUrl } from "../../../shared-logic/utils/uscUtils";
import * as CompareItem from "./styles/CompareItem";
import CompareActions from "./styles/CompareActions";
import { trackAemUsedCompareCtaClick } from "../../utils/tracking";
import { LoadedUsedCompareCarType } from "../../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import * as Preloader from "../../../../common-deprecated/styles/toyota/Preloader";
import ButtonPrimary from "../../../../common-deprecated/components/aem/toyota/ButtonPrimary";
import ButtonSecondary from "../../../../common-deprecated/components/aem/toyota/ButtonSecondary";
import { getContactDealerCta } from "../../utils/ctaUtils";
import useLocalContactDealerClick from "../../../shared-logic/hooks/useLocalContactDealerClick";
import { UscContext } from "../../../shared-logic/types/UscCommonTypes";

const CarCTAs = ({ car }: { car: LoadedUsedCompareCarType }): JSX.Element | null => {
    const dispatch = useDispatch<CompareUSCDispatchType>();
    const commonSettings = useCompareUSCSelector((state) => state.commonSettings);
    const settings = useCompareUSCSelector((state) => state.compareUscSettings);

    const localContactDealerClick = useLocalContactDealerClick({
        // Compare only supports used cars
        context: UscContext.Used,
        dealer: car.dealer,
        vehicle: car,
        detailPageUrl: settings.urlUsedCarsDetailPage,
        localContactDealerFormUrl: settings.localContactDealerFormUrl,
        trackingFn: () => trackAemUsedCompareCtaClick("uc-vehicle-request"),
    });

    const { label, click } = getContactDealerCta(
        car,
        settings.urlUsedCarsDetailPage,
        commonSettings,
        settings,
        car.dealer,
        dispatch,
        () => trackAemUsedCompareCtaClick("uc-vehicle-request"),
        localContactDealerClick,
    ) || { label: "", click: null };

    const getCarUrl = (pageUrl: string): string => {
        const urlInfo = getUscVehicleUrlInfoFromCompareResult(car);
        return getUscUrl(commonSettings, pageUrl, "used", urlInfo);
    };

    return (
        <CompareItem.Wrapper>
            <CompareActions>
                {settings.enableContactDealerForm && label && click && (
                    <ButtonPrimary onClick={click}>{label}</ButtonPrimary>
                )}
                <ButtonSecondary
                    href={getCarUrl(settings.urlUsedCarsDetailPage)}
                    onClick={() => {
                        trackAemUsedCompareCtaClick("uc-detail-page");
                    }}
                >
                    <CompareUSCLabel label="usedCompareButtonSeeCar" />
                </ButtonSecondary>
            </CompareActions>
        </CompareItem.Wrapper>
    );
};

const UsedCtaContainer = (): JSX.Element => {
    const cars = useCompareUSCSelector((state) => state.usedCarCompareExternalData.cars);
    const hidePrintButton = useCompareUSCSelector((state) => state.compareUscSettings.hidePrintButton);

    return (
        <>
            <CompareTable
                items={cars}
                columnChild={(car) => {
                    if (car.loading) {
                        return (
                            <Preloader.Wrapper width={1} position="relative">
                                <Preloader.default />
                            </Preloader.Wrapper>
                        );
                    }

                    return <CarCTAs car={car} />;
                }}
            />
            {!hidePrintButton && <PdfLink />}
        </>
    );
};

export default UsedCtaContainer;
