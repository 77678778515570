import styled, { css } from "styled-components";
import { getBreakpoint, getDirection } from "../../../../../common-deprecated/themes/common";
import { theme } from "../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import Preloader from "../../../../../common-deprecated/styles/v2/toyota/custom/Preloader";
import * as SelectPane from "../../../../../common-deprecated/styles/v2/toyota/custom/SelectPane";
import * as Button from "../../../../../common-deprecated/styles/v2/toyota/components/Button";
import * as Text from "../../../../../common-deprecated/styles/v2/toyota/utilities/Text";
import * as CompareStyles from "./CompareStyles";

export const Wrapper = styled.div<{ expanded?: boolean; isStandalone?: boolean }>`
    display: flex;
    position: fixed;
    top: 0;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: ${theme.space[3]}px;
    color: ${theme.colors.primaryWhite};
    background-color: ${theme.colors.primaryBlack};
    border-radius: 8px 8px 0 0;
    transform: translateY(-100%);
    transition: border-radius 0.3s, transform 0.5s;
    z-index: ${theme.zIndex.popover + 1};

    ${({ expanded }) =>
        expanded &&
        css`
            position: fixed;
            @supports (position: sticky) {
                position: sticky;
            }

            top: 0;
            left: 0;
            right: 0;
            transform: translateY(0);
            border-radius: 0;
        `};

    @media (min-width: 1200px) {
        flex-direction: row;
    }

    h1 {
        font-family: ${theme.fonts.semiBold};
        font-size: 1.7rem;
        letter-spacing: 0;
        line-height: 1.9rem;
        text-align: ${getDirection("left")};
        color: ${theme.colors.primaryWhite};
    }

    ${SelectPane.Wrapper} {
        @media ${getBreakpoint("up", "md")} {
            position: absolute;
            top: calc(100% + ${theme.space[3]}px);
        }
    }
`;

// TODO: css animation breaks because of performance
export const MaskedPreloader = styled(Preloader)<{ show?: boolean }>`
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: ${theme.colors.primaryBlack};
    transition: opacity 0.3s;
    opacity: 0;
    ${({ show }) =>
        show &&
        css`
            opacity: 0.7;
        `};
`;

export const Fixed = styled(Wrapper)<{ isHeaderFixed?: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${theme.colors.primaryBlack};
    transform: translateY(${({ isHeaderFixed }) => (isHeaderFixed ? "0" : "-130px")});
    transition: transform 0.2s ease-in-out;
    z-index: ${theme.zIndex.pageoverlayerExpansion};
`;

export const Actions = styled.div<{ hidden?: boolean; align?: "left" | "right" }>`
    display: flex;
    flex: 1 1 auto;
    align-items: flex-end;
    align-self: flex-start;
    width: 50%;
    visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};

    ${({ align }) =>
        align === "right"
            ? css`
                  justify-content: flex-end;
                  order: 2;
                  @media (min-width: 1200px) {
                      position: absolute;
                      top: ${theme.space[3]}px;
                      ${getDirection("right")}: ${theme.space[3]}px;
                      order: 3;
                  }
              `
            : css`
                  justify-content: flex-start;
                  order: 1;
                  @media (min-width: 1200px) {
                      position: absolute;
                      top: ${theme.space[3]}px;
                      ${getDirection("left")}: ${theme.space[3]}px;
                  }
              `};

    @media (min-width: 1200px) {
        width: auto;
    }

    ${Button.Primary} {
        padding: ${theme.space[1]}px ${theme.space[2]}px;
        font-size: 1.3rem;
        line-height: 2rem;
        background-color: ${theme.colors.primaryWhite};

        ${Text.Button} {
            --color: ${theme.colors.primaryBlack};
            --backgroundColor: ${theme.colors.primaryWhite};
            padding: 0 !important;
            font-size: 1.3rem;
            line-height: 2rem;
            color: ${theme.colors.primaryBlack};
            border: none;
        }

        /* Override some CSS styles :( */
        .icon {
            margin: 0 !important;
            padding: 0 !important;
        }
    }
`;

export const ItemWrapper = styled(CompareStyles.Container)`
    position: relative;
    order: 3;
    margin: ${theme.space[3]}px 0 0;

    @media (min-width: 1200px) {
        order: 2;
        margin: 0 auto;
    }
`;

export const ButtonClearAll = styled(Button.Secondary)`
    display: flex;
    height: auto;
    margin: 0;
    font-size: 1.3rem;
    line-height: 2rem;
    border-color: transparent !important;

    ${Text.Button} {
        --color: ${theme.colors.primaryWhite};
        --backgroundColor: transparent;
        padding: 0;
        padding-${getDirection("right")}: ${theme.space[1]}px;
    }
`;

export const DisclaimerWrappers = styled.div`
    order: 4;
    width: 100%;
    &:not(:empty) {
        margin-top: ${theme.space[2]}px;
    }
`;
