import styled from "styled-components";
import { theme } from "../theme/toyotaTheme";

export const Wrapper = styled.div`
    margin: ${theme.space[3]}px 0;
    padding-top: ${theme.space[3]}px;
    font-family: ${theme.fonts.regular};
    color: ${theme.colors.grey4};
    border-top: 1px solid ${theme.colors.grey2};
`;

export const Title = styled.div.attrs(() => ({
    className: "t-milli-headline",
}))`
    margin-bottom: ${theme.space[2]}px;
    text-transform: uppercase;
    color: ${theme.colors.grey4};
`;

export const Text = styled.div.attrs(() => ({
    className: "t-milli-text",
}))`
    margin-bottom: ${theme.space[2]}px;
    color: ${theme.colors.grey4};
`;

/**
 * DisclaimersWrapper is used as wrapper for disclaimers on container pages like PDPContainer etc...
 */
export const DisclaimersWrapper = styled.div`
    &:not(:empty) {
        width: 100%;
        margin-bottom: ${theme.space[5]}px;
    }
`;
