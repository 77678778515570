import styled, { css } from "styled-components";

const BREAK_AFTER_BEFORE_TYPES = [
    "auto", // generic break values
    "avoid",
    "always",
    "all",
    "avoid-page", // page break values
    "page",
    "left",
    "right",
    "recto",
    "verso",
    "avoid-column", // column break values
    "column",
    "avoid-region", // region break values
    "region",
];

type BreakAfterBefore = {
    value: (typeof BREAK_AFTER_BEFORE_TYPES)[number];
};

type BreakInside = {
    value: "auto" | "avoid" | "avoid-page" | "avoid-column" | "avoid-region";
};

/**
 * breakInsideStyle is an utility component
 */
export const breakInsideStyle = css<BreakInside>`
    break-inside: ${({ value }) => value};
`;

export const breakAfterStyle = css<BreakAfterBefore>`
    break-after: ${({ value }) => value};
`;

export const breakBeforeStyle = css<BreakAfterBefore>`
    break-before: ${({ value }) => value};
`;

/**
 * PageBreakInside
 */
export const PageBreakInside = styled.div<BreakInside>`
    ${breakInsideStyle};
`;

/**
 * PageBreakAfter
 */
export const PageBreakAfter = styled.div<BreakAfterBefore>`
    ${breakAfterStyle};
`;

/**
 * PageBreakBefore
 */
export const PageBreakBefore = styled.div<BreakAfterBefore>`
    ${breakBeforeStyle};
`;
