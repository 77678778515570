import styled, { css } from "styled-components";
import { getDirection } from "../../../../../common-deprecated/themes/common";
import { theme } from "../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import * as Tooltip from "../../../../../common-deprecated/styles/v2/toyota/components/Tooltip";
import * as Button from "../../../../../common-deprecated/styles/v2/toyota/components/Button";

export const Wrapper = styled.div`
    height: auto;
    padding: 0 ${theme.space[2]}px ${theme.space[2]}px;
    text-align: ${getDirection("left")};
`;

export const Title = styled.div.attrs(() => ({
    className: "t-milli-text",
}))`
    color: ${theme.colors.grey7};
    ${Tooltip.PopoverButton} {
        margin-${getDirection("left")}: ${theme.space[1]}px;
        .icon::before {
            position: relative;
            top: 0;
            font-size: 1.3rem;
            line-height: 2.4rem;
            color: ${theme.colors.grey7};
        }
    }
`;

export const Value = styled.span.attrs(() => ({
    className: "t-base-text",
}))<{ isLoading?: boolean; discountedPrice?: boolean }>`
    display: inline-block;
    position: relative;
    padding-${getDirection("right")}: ${theme.space[1]}px;
    text-decoration: ${({ discountedPrice }) => (discountedPrice ? "line-through" : "none")};
    color: ${({ discountedPrice }) => (discountedPrice ? theme.colors.grey3 : theme.colors.primaryBlack)};

    ${({ isLoading }) =>
        isLoading &&
        css`
            &:after {
                content: "0";
                background: ${theme.colors.grey1};
                color: ${theme.colors.grey1};
                width: 100%;
                position: absolute;
                ${getDirection("left")}: -5px;
                filter: blur(2px);
                width: 200px;
                right: 0;
                transition: width 0.3s ease;
            }
        `};
`;

export const DualPriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ValueWrapper = styled.div`
    display: flex;
`;

export const KeyValue = styled.div<{ hasSmallFontSize?: boolean }>`
    text-align: ${getDirection("left")};
    ${Value} {
        font-size: ${({ hasSmallFontSize }) => (hasSmallFontSize ? "1.3rem" : "1.6rem")};
        line-height: ${({ hasSmallFontSize }) => (hasSmallFontSize ? "2rem" : "2.4rem")};
    }
`;

export const Suffix = styled.span`
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${theme.colors.primaryBlack};
`;

export const Disclaimer = styled.button`
    padding: 0;
    background: transparent;
    border: none;

    .icon, svg {
        margin-${getDirection("left")}: ${theme.space[1]}px;
    }
`;

export const DisclaimerIconWrapper = styled.span`
    display: flex;
    align-items: center;
    margin-${getDirection("left")}: ${theme.space[1]}px;
`;

export const LegalText = styled.div`
    margin: ${theme.space[1]}px 0 0;
    font-size: 1.3rem;
    line-height: 1.5rem;
    text-align: ${getDirection("left")};
    text-indent: 0;
    color: ${theme.colors.grey4};

    ${Button.Link} {
        text-decoration: underline;
        color: ${theme.colors.grey4};
    }
`;
