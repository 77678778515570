import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { getBreakpoint, getDirection } from "../../../../../common-deprecated/themes/common";
import * as CompareStyles from "./CompareStyles";
import * as Button from "../../../../../common-deprecated/styles/toyota/Button";
import * as Toggle from "../../../../../common-deprecated/styles/v2/toyota/components/Toggle";
import Text from "../../../../../common-deprecated/styles/toyota/Text";
import { theme } from "../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import * as CollapseStyles from "../../../../../common-deprecated/styles/v2/toyota/custom/Collapse";

export const Wrapper = styled(CompareStyles.Row)<SpaceProps>`
    flex-direction: column;
    ${space};

    ${CollapseStyles.Wrapper} {
        padding: 0 ${theme.space[1]}px;
    }
`;

export const Header = styled.header`
    margin-bottom: 32px;

    ${Toggle.Wrapper} {
        width: 100%;
        @media ${getBreakpoint("up", "md")} {
            width: auto;
        }
    }
`;

export const Title = styled.h2.attrs(() => ({
    className: "t-gamma-text",
}))`
    font-size: 2rem;
    text-align: ${getDirection("left")};
    text-transform: none;

    @media ${getBreakpoint("up", "lg")} {
        font-size: 2.8rem;
    }
`;

export const Description = styled.div<{ isPdf?: boolean } & SpaceProps>`
    position: relative;
    padding: ${theme.space[3]}px 0;
    font-size: 1.6rem;
    ${space};

    @media ${getBreakpoint("up", "md")} {
        display: ${({ isPdf }) => (isPdf ? "block" : "flex")};
        align-items: center;
        justify-content: space-between;
        text-align: ${getDirection("left")};
    }

    ${Text} {
        position: relative;
        flex-shrink: 1;
        margin-bottom: 20px;
        padding-${getDirection("left")}: 80px;
        @media ${getBreakpoint("up", "md")} {
            margin-bottom: 0;
        }

        svg {
            position: absolute;
            top: 50%;
            ${getDirection("left")}: 0;
            margin-top: -40px;
            margin-${getDirection("right")}: ${theme.space[1]}px;
        }
    }

    ${Button.default} {
        flex-shrink: 0;

        @media ${getBreakpoint("down", "md")} {
            flex-grow: 1;
            justify-content: center;
            width: 100%;
        }

        svg {
            top: 0;
        }
    }
`;
