import styled, { css } from "styled-components";
import { getBreakpoint, getDirection } from "../../../../themes/common";
import { theme as toyotaTheme } from "../theme/toyotaTheme";
import * as Button from "../components/Button";
import { IconWrapper } from "../globals/IconWrapper";

export const Wrapper = styled.section<{ height?: number | null; top?: number | null }>`
    ${({ height, top }) =>
        height &&
        top &&
        css`
            position: fixed;
            height: ${height}px;
            top: ${top}px;
            left: 0;
            right: 0;
            z-index: ${toyotaTheme.zIndex.overlay};
            @media ${getBreakpoint("up", "md")} {
                height: initial;
                top: 100%;
            }
        `}
    color: ${toyotaTheme.colors.primaryBlack};
    background-color: ${toyotaTheme.colors.primaryWhite};
    overflow: hidden;
`;

export const Header = styled.header`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    flex-direction: column;
    align-items: flex-start;
    height: 110px;
    padding: ${toyotaTheme.space[3]}px;
    font-family: ${toyotaTheme.fonts.regular};
    background-color: ${toyotaTheme.colors.primaryWhite};
    cursor: pointer;
    z-index: 100;

    ${IconWrapper} {
        width: 24px;
        margin-${getDirection("right")}: ${toyotaTheme.space[2]}px;
    }
`;

export const HeaderButton = styled.button`
    display: flex;
`;

export const HeaderLabel = styled.div.attrs(() => ({
    className: "t-epsilon-text",
}))`
    display: inline-block;
    max-width: 100%;
    margin: ${toyotaTheme.space[1]}px 0 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: ${toyotaTheme.colors.primaryWhite};
    overflow: hidden;
`;

export const Body = styled.div`
    transition: transform 0.25s ease-in-out;
`;

export const Panel = styled.div<{ top?: number | null }>`
    position: relative;
    width: 100vw;
    overflow: hidden;
`;

// total width and left offset can are calculated dynamically so this component can be used with both 2 and 3 panels
// 160px is the height of the SelectPane Footer on mobile
export const Panels = styled.div<{
    currentPanel?: number;
    panelCount?: number;
    height?: number | null;
    compareHeaderHeight?: number | null;
}>`
    display: flex;
    width: ${({ panelCount }) => (panelCount || 1) * 100}%;
    transform: ${({ currentPanel, panelCount, theme }) =>
        `translateX(${theme.isRTL ? "" : "-"}${((currentPanel || 0) * 100) / (panelCount || 1)}%)`};
    transition: transform 0.25s ease-in-out;

    ${({ compareHeaderHeight, height }) =>
        compareHeaderHeight &&
        css`
            ${Panel} {
                /* 160px is the height of the footer */
                max-height: calc(${height}px - ${compareHeaderHeight}px - 160px);
                @media ${getBreakpoint("up", "md")} {
                    max-height: 250px;
                }
            }
        `}
`;

export const PanelContent = styled.div`
    position: relative;
    height: 100%;
    padding-top: 110px;
`;

export const PanelScroll = styled.div`
    height: 100%;
    font-size: 1.3rem;
    overflow-y: auto;
`;

export const PanelTitle = styled.div`
    padding: ${toyotaTheme.space[3]}px;
    &::before {
        content: "-";
        padding-${getDirection("right")}: ${toyotaTheme.space[2]}px;
    }

    &::after {
        content: "-";
        padding-${getDirection("left")}: ${toyotaTheme.space[2]}px;
    }
`;

export const PanelStep = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${toyotaTheme.space[1]}px ${toyotaTheme.space[3]}px;
    font-family: ${toyotaTheme.fonts.regular};
    font-size: 1.7rem;
    color: ${toyotaTheme.colors.primaryBlack};
    background-color: ${toyotaTheme.colors.grey1};
    border-bottom: 1px solid ${toyotaTheme.colors.grey2};
`;

export const PanelStepTitle = styled.div`
    color: ${toyotaTheme.colors.primaryBlack};
`;

export const PanelItem = styled.button<{ selected?: boolean; configuration?: boolean }>`
    display: flex;
    flex-direction: ${({ configuration }) => (configuration ? "column" : "row")};
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: ${toyotaTheme.space[3]}px;
    font-size: 2rem;
    line-height: 2.4rem;
    text-align: start;
    border-bottom: 1px solid ${toyotaTheme.colors.grey2};
    cursor: pointer;
    &:hover {
        background-color: ${toyotaTheme.colors.grey1};
    }
    ${({ selected }) =>
        selected &&
        css`
            font-family: ${toyotaTheme.fonts.bold};
            background-color: ${toyotaTheme.colors.grey1};
        `};

    ${({ selected, theme }) =>
        selected &&
        css`
            font-family: ${theme.fonts.bold};
            background-color: ${theme.colors.grey1};
        `};
`;

export const PackItem = styled.div`
    width: 100%;
    padding: 4px 8px;
    font-size: 1.2rem;
    background-color: ${toyotaTheme.colors.grey2};
    border-radius: 2px;
`;

export const Footer = styled.footer`
    padding: ${toyotaTheme.space[3]}px;

    ${Button.Primary},
    ${Button.Outline} {
        width: 100%;
        margin-bottom: 0 !important; /* Important is needed to override CSS styles :( */
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    ${Button.Outline} {
        margin-top: ${toyotaTheme.space[2]}px;
    }
`;
