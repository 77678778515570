import React from "react";
import { shallowEqual } from "react-redux";
import { useCompareUSCSelector } from "../../redux/store";
import { disclaimerSelector, getDisclaimerId } from "../../utils/disclaimerUtils";
import NumberedDisclaimerIcon from "../../../../common-deprecated/styles/v2/toyota/custom/NumberedDisclaimerIcon";
import * as PageStyles from "../../../../common-deprecated/styles/v2/toyota/utilities/Page";
import * as Styles from "../../../../common-deprecated/styles/v2/toyota/custom/PageDisclaimer";
import { useCompareUSCLabel, useCompareUSCOptionalLabel } from "../../utils/labels";
import { DisclaimerLocation } from "../../../../common-deprecated/types/CommonTypes";

const PageDisclaimers = (): JSX.Element | null => {
    const disclaimers = useCompareUSCSelector(disclaimerSelector, shallowEqual);
    const footnoteDisclaimers = disclaimers.filter((disclaimer) => disclaimer.location === DisclaimerLocation.Footnote);

    const disclaimerTitleLabel = useCompareUSCLabel("usedComparePageDisclaimerTitle");
    const disclaimerLabel = useCompareUSCOptionalLabel("usedComparePageDisclaimer");

    if (!disclaimerLabel && !footnoteDisclaimers.length) return null;

    return (
        <Styles.Wrapper>
            <Styles.Title>{disclaimerTitleLabel}</Styles.Title>
            {disclaimerLabel && <Styles.Text>{disclaimerLabel}</Styles.Text>}
            {footnoteDisclaimers.map((disclaimer) => {
                const id = getDisclaimerId(disclaimer);
                return (
                    <Styles.Text key={id}>
                        <PageStyles.PageBreakInside value="avoid" id={id}>
                            <NumberedDisclaimerIcon>{disclaimer.number}</NumberedDisclaimerIcon>
                            {disclaimer.containsHtml ? (
                                <span dangerouslySetInnerHTML={{ __html: disclaimer.value }} />
                            ) : (
                                <span>{disclaimer.value}</span>
                            )}
                        </PageStyles.PageBreakInside>
                    </Styles.Text>
                );
            })}
        </Styles.Wrapper>
    );
};

export default PageDisclaimers;
