import React, { useRef } from "react";
import { useCloseOnOutsideClick } from "../../../../../../common-deprecated/hooks";
import IconWrapper from "../../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import * as Tooltip from "../../../../../../common-deprecated/styles/v2/toyota/components/Tooltip";
import { IconClose } from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";

type ColourSpecValuePopupPropsType = {
    closePopup: () => void;
    content: JSX.Element;
};

const ColourSpecValuePopup = (props: ColourSpecValuePopupPropsType): JSX.Element => {
    const { content, closePopup } = props;

    const tooltipRef = useRef<HTMLDivElement>(null);
    useCloseOnOutsideClick(tooltipRef, closePopup);

    return (
        <Tooltip.Wrapper role="tooltip" ref={tooltipRef} absolute>
            <Tooltip.Close onClick={closePopup}>
                <IconWrapper iconColor="grey3">
                    <IconClose />
                </IconWrapper>
            </Tooltip.Close>
            {content}
        </Tooltip.Wrapper>
    );
};

export default ColourSpecValuePopup;
