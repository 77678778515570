import React from "react";
import useUsedCarSpecs from "../../../hooks/useUsedCarSpecs";
import { usedSpecsLabelMap } from "../../../utils/labels";
import UsedPriceSpec from "./UsedPriceSpec";
import UsedQuickSpecRow from "./UsedQuickSpecRow";
import { UsedCompareSpec } from "../../../utils/usedSpecsUtils";
import { useCompareUSCSelector } from "../../../redux/store";

const SpecsWrapper = ({ isPdf }: { isPdf?: boolean }): JSX.Element => {
    const { primarySpecs, secondarySpecs, TTMSSpecs } = useUsedCarSpecs(isPdf);
    const country = useCompareUSCSelector((state) => state.commonSettings.country);

    return (
        <>
            {primarySpecs.map((spec) =>
                spec.type === UsedCompareSpec.Price && spec.values.price ? (
                    <UsedPriceSpec key={spec.type} price={spec.values.price} />
                ) : (
                    <UsedQuickSpecRow
                        key={spec.type}
                        type={spec.type}
                        title={usedSpecsLabelMap[spec.type]}
                        values={spec.values.values}
                        suffix={spec.values.unit}
                        hasSmallFontSize={spec.type === UsedCompareSpec.Engine}
                        isMessageLabel
                    />
                ),
            )}

            {secondarySpecs.map((spec) => (
                <UsedQuickSpecRow
                    key={spec.type}
                    type={spec.type}
                    title={usedSpecsLabelMap[spec.type]}
                    values={spec.values.values}
                    suffix={spec.values.unit}
                    hasSmallFontSize
                    isMessageLabel
                />
            ))}

            {country.toLowerCase() === "tr" &&
                TTMSSpecs.map((spec) => (
                    <UsedQuickSpecRow
                        key={spec.type}
                        type={spec.type}
                        title={usedSpecsLabelMap[spec.type]}
                        values={spec.values.values}
                        suffix={spec.values.unit}
                        hasSmallFontSize
                        isMessageLabel
                    />
                ))}
        </>
    );
};

export default SpecsWrapper;
