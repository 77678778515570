import styled from "styled-components";
import { height, HeightProps, maxWidth, MaxWidthProps } from "styled-system";

export const ImageFluid = styled.img<MaxWidthProps & HeightProps>`
    max-width: 100%;

    ${height};
    ${maxWidth}

    &[data-aspect-ratio="16:9"] {
        aspect-ratio: 16/9;
        object-fit: cover;
    }

    &[data-aspect-ratio="4:3"] {
        aspect-ratio: 4/3;
        object-fit: cover;
    }
`;

export default ImageFluid;
