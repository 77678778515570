import React from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { DisclaimerLocation } from "../../../../../common-deprecated/types/CommonTypes";
import { showModal } from "../../../../../common-deprecated/redux/actions/ModalActions";
import { MODAL_CONTENT } from "../../../../../common-deprecated/utils/modalConstants";
import { CompareUSCDispatchType, useCompareUSCSelector } from "../../../redux/store";
import { disclaimerSelector, UsedCarCompareDisclaimerType } from "../../../utils/disclaimerUtils";
import * as Button from "../../../../../common-deprecated/styles/v2/toyota/components/Button";

type Props = { type: UsedCarCompareDisclaimerType; reference?: string };

const LinkTextDisclaimer = ({ type, reference }: Props): JSX.Element | null => {
    const dispatch = useDispatch<CompareUSCDispatchType>();
    const disclaimers = useCompareUSCSelector(disclaimerSelector, shallowEqual);
    const infoTextDisclaimer = disclaimers.find(
        (disclaimer) =>
            disclaimer.location === DisclaimerLocation.InfoText &&
            disclaimer.type === type &&
            disclaimer.reference === reference,
    );

    if (!infoTextDisclaimer) return null;

    return (
        <Button.Link
            type="button"
            onClick={() =>
                dispatch(showModal({ type: MODAL_CONTENT, content: infoTextDisclaimer.value, useHTML: true }))
            }
        >
            {infoTextDisclaimer.infoText}
        </Button.Link>
    );
};

export default LinkTextDisclaimer;
