import React, { FC } from "react";
import useIntegratedUsedCompare from "../hooks/useIntegratedUsedCompare";
import UsedCompareToyota from "./UsedCompareToyota";

const IntegratedUsedCompareToyota: FC = () => {
    const { fetching } = useIntegratedUsedCompare();

    return fetching ? null : <UsedCompareToyota />;
};

export default IntegratedUsedCompareToyota;
