import React, { useState } from "react";
import Icon from "../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import IconAdd from "../../../../../../assets/toyota/svg/icon-add.svg";
import { EquipmentSpecNameIdValueType } from "../../../../../common-deprecated/features/compare/utils/newCarCompareTypes";
import * as Button from "../../../../../common-deprecated/styles/v2/toyota/components/Button";
import ColourSpecValuePopup from "../highlight/content/ColourSpecValuePopup";
import { IconWrapper } from "../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import { CompareUSCLabel } from "../../../utils/labels";
import { SrOnly } from "../../../../../common-deprecated/styles/v2/toyota/utilities/ScreenreaderOnly";

type CompareEquipmentOrSpecValuePropsType = {
    value: EquipmentSpecNameIdValueType;
    carColour?: string;
};

const EquipmentOrSpecValue = (props: CompareEquipmentOrSpecValuePropsType): JSX.Element => {
    const { value, carColour } = props;
    const [showPopup, setShowPopup] = useState<boolean>(false);

    switch (value.value) {
        case "s":
            // This equipment item is standard, but can be not applicable for certain colours, we check that first:
            if (carColour && value.colours && value.colours.length && !value.colours.includes(carColour)) {
                return (
                    <>
                        <IconWrapper iconColor="grey4">
                            <Icon variant="minus" />
                        </IconWrapper>
                        <Button.Link
                            p={0}
                            onClick={() => {
                                setShowPopup(!showPopup);
                            }}
                        >
                            <IconWrapper iconColor="grey4">
                                <Icon variant="info" />
                            </IconWrapper>
                        </Button.Link>
                        {showPopup && (
                            <ColourSpecValuePopup
                                content={
                                    <>
                                        {value.colours.map((c) => {
                                            return (
                                                <p key={c}>
                                                    <IconWrapper iconColor="success">
                                                        <Icon variant="check" />
                                                    </IconWrapper>
                                                </p>
                                            );
                                        })}
                                    </>
                                }
                                closePopup={() => {
                                    setShowPopup(false);
                                }}
                            />
                        )}
                    </>
                );
            }
            return (
                <IconWrapper iconColor="success">
                    <Icon variant="check" />
                    <SrOnly>
                        <CompareUSCLabel label="compareBodyLegendStandard" />
                    </SrOnly>
                </IconWrapper>
            );
        case "o":
            // This equipment item is optional, but can be not applicable for certain colours, we check that first:
            if (carColour && value.colours && value.colours.length && !value.colours.includes(carColour)) {
                return (
                    <>
                        <IconWrapper iconColor="grey4">
                            <Icon variant="minus" />
                        </IconWrapper>
                        <Button.Link
                            p={0}
                            onClick={() => {
                                setShowPopup(!showPopup);
                            }}
                        >
                            <IconWrapper iconColor="grey4">
                                <Icon variant="info" />
                            </IconWrapper>
                        </Button.Link>
                        {showPopup && (
                            <ColourSpecValuePopup
                                content={
                                    <>
                                        {value.colours.map((c) => {
                                            return (
                                                <p key={c}>
                                                    <IconWrapper iconColor="grey4">
                                                        <Icon variant="customize" />
                                                    </IconWrapper>
                                                </p>
                                            );
                                        })}
                                    </>
                                }
                                closePopup={() => {
                                    setShowPopup(false);
                                }}
                            />
                        )}
                    </>
                );
            }
            return (
                <IconWrapper iconColor="grey4">
                    <Icon variant="customize" />
                </IconWrapper>
            );
        case "op":
            return <IconAdd aria-hidden="true" />;
        case "":
            return (
                <IconWrapper iconColor="grey4">
                    <Icon variant="minus" />
                    <SrOnly>
                        <CompareUSCLabel label="compareBodyLegendNotApplicable" />
                    </SrOnly>
                </IconWrapper>
            );
        default:
            return <>{value.value}</>;
    }
};

export default EquipmentOrSpecValue;
