import React from "react";
import useItemDescription from "../../../../../shared-logic/features/compare/hooks/useItemDescription";
import { CompareUSCLabel } from "../../../utils/labels";
import * as CompareSpecs from "../styles/CompareSpecs";
import { ToggleIconWrapper } from "../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import Icon from "../../../../../common-deprecated/styles/v2/toyota/globals/Icon";

export type CompareDescriptionType = {
    description?: string;
    isPdf?: boolean;
    heightLimit: number;
};
const ItemDescription = ({ isPdf, description, heightLimit }: CompareDescriptionType): JSX.Element | null => {
    const { height, contentRef, open, setOpen } = useItemDescription(isPdf);

    return description ? (
        <>
            <CompareSpecs.Description
                heightLimit={heightLimit}
                fullHeight={height}
                showReadMore={isPdf ? false : height > heightLimit}
                open={open}
                ref={contentRef}
            >
                <span dangerouslySetInnerHTML={{ __html: description }} />
            </CompareSpecs.Description>
            {height > heightLimit && !isPdf && (
                <CompareSpecs.ReadMoreLess onClick={() => setOpen(!open)}>
                    {open ? (
                        <CompareUSCLabel label="T1.readLess" fallbackLabel="readLess" />
                    ) : (
                        <CompareUSCLabel label="T1.readMore" fallbackLabel="readMore" />
                    )}
                    <ToggleIconWrapper isDown={!open}>
                        <Icon variant="chevronDown" />
                    </ToggleIconWrapper>
                </CompareSpecs.ReadMoreLess>
            )}
        </>
    ) : null;
};

export default ItemDescription;
