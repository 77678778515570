import React from "react";
import { useCompareUSCSelector } from "../../../redux/store";
import SpecsWrapper from "./SpecsWrapper";
import CompareTable from "../../common/CompareTable";
import * as CompareItem from "../styles/CompareItem";
import { ImageFluid } from "../../../../../common-deprecated/styles/v2/toyota/globals/Image";
import Preloader from "../../../../../common-deprecated/styles/v2/toyota/custom/Preloader";
import * as Dropdown from "../../../../../common-deprecated/styles/v2/toyota/globals/Dropdown";
import { SrOnly } from "../../../../../common-deprecated/styles/v2/toyota/utilities/ScreenreaderOnly";

type UsedCarCompareDetailsType = {
    isPdf?: boolean;
};

const UsedCompareDetails = ({ isPdf }: UsedCarCompareDetailsType): JSX.Element => {
    const cars = useCompareUSCSelector((state) => state.usedCarCompareExternalData.cars);
    const aspectRatio = useCompareUSCSelector((state) => state.compareUscSettings.aspectRatio);
    const objectFit = useCompareUSCSelector((state) => state.compareUscSettings.objectFit);
    const notAllCarsAreLoaded = !!cars.filter((car) => car.loading).length;

    return (
        <>
            <div role="rowgroup">
                <div role="row">
                    {cars.map((car) => (
                        <span role="columnheader" key={car.id}>
                            <SrOnly>
                                {car.product.brand.description} {car.product.model}
                            </SrOnly>
                        </span>
                    ))}
                </div>
            </div>
            <div className="rowgroup">
                <CompareTable
                    items={cars}
                    columnChild={(car) => (
                        <CompareItem.Wrapper key={car.id}>
                            {isPdf && (
                                <Dropdown.Title showPointer={false} height={60}>
                                    {car.product.brand.description} {car.product.model}
                                </Dropdown.Title>
                            )}
                            <CompareItem.Placeholder objectFit={objectFit} isLoading={car.loading}>
                                {car.loading ? (
                                    <Preloader />
                                ) : (
                                    <ImageFluid
                                        src={car.images[0]?.url}
                                        draggable={false}
                                        alt={`${car.product.brand.description} ${car.product.model}`}
                                        data-aspect-ratio={aspectRatio}
                                    />
                                )}
                            </CompareItem.Placeholder>
                        </CompareItem.Wrapper>
                    )}
                />
                {!notAllCarsAreLoaded && <SpecsWrapper isPdf={isPdf} />}
            </div>
        </>
    );
};

export default UsedCompareDetails;
