import styled, { css } from "styled-components";
import { getDirection } from "../../../../themes/common";
import { theme } from "../theme/toyotaTheme";

export const Wrapper = styled.div`
    display: inline-flex;
    padding-${getDirection("left")}: ${theme.space[3]}px;
    padding-${getDirection("right")}: ${theme.space[7]}px;
    border-bottom: 1px solid ${theme.colors.grey2};
    @media print {
        display: none;
    }
`;

export const Item = styled.button<{ active?: boolean }>`
    display: flex;
    position: relative;
    top: 1px;
    flex: 1;
    max-width: 100%;
    padding-bottom: ${theme.space[3]}px;
    font-family: ${theme.fonts.base};
    font-size: 1.6rem;
    line-height: 2.4rem;
    white-space: nowrap;
    border-bottom: 1px solid ${theme.colors.grey2};
    transition: border 0.2s;
    cursor: pointer;

    &:first-child {
        margin-${getDirection("right")}: ${theme.space[3]}px;
    }

    span {
        display: inline-block;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow-x: hidden;
    }

    ${({ active }) =>
        active &&
        css`
            font-family: ${theme.fonts.regular};
            border-color: ${theme.colors.primaryRed};
        `};

    .bz & {
        &[aria-pressed="true"] {
            border-color: ${theme.colors.bz4x};
        }
    }
`;
