import React from "react";
import { printPdf } from "../../../../common-deprecated/utils/pdfUtils";
import { useCompareUSCSelector } from "../../redux/store";
import { CompareUSCLabel } from "../../utils/labels";
import { getUscComparePdfLink } from "../../utils/uscCompareUtils";
import Hr from "../../../../common-deprecated/styles/v2/toyota/utilities/Hr";
import * as Compare from "./styles/CompareStyles";
import * as Button from "../../../../common-deprecated/styles/v2/toyota/components/Button";
import * as Text from "../../../../common-deprecated/styles/v2/toyota/utilities/Text";
import { IconWrapper } from "../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import Icon from "../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { trackAemUsedComparePdfClick } from "../../utils/tracking";

const PdfLink = (): JSX.Element => {
    const commonSettings = useCompareUSCSelector((state) => state.commonSettings);

    const sharePdf = (): void => {
        const url = getUscComparePdfLink(commonSettings);
        trackAemUsedComparePdfClick();
        // This opens the PDF in new tab
        printPdf(url, commonSettings);
    };

    return (
        <div>
            <Hr />
            <Compare.PdfSection>
                {/* Button gets hidden by applying base style in the template */}
                <Button.Link large type="button" onClick={() => sharePdf()} hasIconRight>
                    <Text.Button>
                        <CompareUSCLabel label="compareBodySaveAsPdf" />
                    </Text.Button>
                    <IconWrapper size="lg" iconColor="primaryBlack" ml={2}>
                        <Icon variant="download" />
                    </IconWrapper>
                </Button.Link>
            </Compare.PdfSection>
        </div>
    );
};

export default PdfLink;
