import styled, { css } from "styled-components";
import { getDirection } from "../../../../themes/common";
import { theme, styledSystemTheme } from "../theme/toyotaTheme";
import { ToggleIconWrapper } from "./IconWrapper";
import { bodyStyle } from "./Type";

export const Wrapper = styled.div<{ onlyOneOption?: boolean; isLoading?: boolean }>`
    position: relative;
    padding: 5px 0;
    text-align: ${getDirection("left")};
    cursor: ${({ onlyOneOption, isLoading }) => (onlyOneOption || isLoading ? "default" : "pointer")};
    svg {
        display: flex;
        flex-shrink: 0;
        @media print {
            display: none;
        }
    }
`;

export const WrapperDarkVariant = styled.div`
    position: relative;
    height: 46px;
    margin-bottom: ${theme.space[3]}px;
    font-size: 1.1rem;
    background: ${theme.colors.grey1};
    border-radius: 4px;
`;

export const Toggle = styled.a<{ isOpen: boolean }>`
    display: block;
    position: relative;
    padding: ${styledSystemTheme.isRTL ? "11px 18px 15px 30px" : "11px 30px 15px 18px"};
    font-size: 1.5rem;
    text-decoration: none;
    color: ${theme.colors.primaryBlack};
    background: ${({ isOpen }) => (isOpen ? theme.colors.grey1 : "none")};
    border-radius: ${({ isOpen }) => (isOpen ? "4px 4px 0 0" : "4px")};
    outline: 0;
    cursor: pointer;

    svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        ${getDirection("right")}: 10px;
    }

    &:hover {
        text-decoration: none;
    }
`;

export const Items = styled.ul`
    display: block;
    position: absolute;
    top: 100%;
    ${getDirection("left")}: 0;
    width: 100%;
    min-width: 160px;
    margin: 2px 0 0;
    margin-top: 0;
    padding: 0;
    font-size: 1.5rem;
    list-style: none;
    text-align: left;
    background-color: ${theme.colors.primaryWhite};
    border: none;
    border-radius: 0;
    box-shadow: none;
    z-index: 1000;
`;

export const Item = styled.li`
    display: block;
    padding: 11px 18px 15px;
    font-size: 1.5rem;
    color: #333;
    background: ${theme.colors.grey1};
    border: none;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05);
    &:hover {
        background: ${theme.colors.grey1};
    }
`;

export const Title = styled.button.attrs(() => ({
    className: "t-base-headline mb-0",
}))<{ selected?: boolean; height?: number; showPointer: boolean; isPdf?: boolean }>`
    display: ${({ isPdf }) => (isPdf ? "inline-block" : "flex")};
    align-items: flex-start;
    justify-content: space-between;
    min-height: ${({ height, isPdf }) => (height && !isPdf ? height : 0)}px;
    padding-bottom: ${theme.space[1]}px;
    text-align: ${getDirection("left")};
    color: ${theme.colors.primaryBlack};
    cursor: ${({ showPointer }) => (showPointer ? "pointer" : "inherit")};
`;

/**
 *  Custom dropdown
 */
export const DropdownWrapper = styled.div<{ margin?: number }>`
    display: inline-block;
    position: relative;
    ${({ margin }) =>
        margin &&
        css`
            margin-right: ${margin}px;
        `};
`;

export const DropdownButton = styled.button`
    ${bodyStyle};
    display: flex;
    justify-content: flex-end;
    width: 100%;
    color: ${theme.colors.primaryBlack};

    &:focus-visible {
        outline: 0;
        box-shadow: 0 0 0 2px ${theme.colors.outline};
    }

    ${ToggleIconWrapper} {
        margin-inline-start: ${theme.space[1]}px;
    }
`;

export const DropdownList = styled.ul<{ isOpen: boolean }>`
    display: block;
    position: absolute;
    top: 32px;
    right: 0;
    width: 254px;
    height: 0;
    margin: 0;
    padding: 0;
    background-color: ${theme.colors.primaryWhite};
    border: 1px solid ${theme.colors.grey2};
    visibility: hidden;
    overflow-y: auto;
    z-index: ${theme.zIndex.dropdown};

    ${({ isOpen }) =>
        isOpen &&
        css`
            height: auto;
            visibility: visible;
        `};
`;

export const DropdownOption = styled.li`
    display: block;
    margin: 0;
    padding: 12px 20px;
    color: ${theme.colors.primaryBlack};
    cursor: pointer;

    &:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.grey2};
    }

    &:hover {
        background-color: ${theme.colors.grey1};
    }

    &:focus-visible {
        box-shadow: 0 0 0 2px ${theme.colors.outline};
    }
`;
