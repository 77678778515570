import React from "react";
import useStatus from "../../../common-deprecated/hooks/useStatus";
import { useModalMap } from "../../../common-deprecated/containers/ModalProvider";
import useInitUsedCompare from "../hooks/useInitUsedCompare";
import AEMUsedCompareView from "./UsedCarCompareView";
import { useCompareUSCSelector } from "../redux/store";
import { aemToyotaCompareUscModalMap } from "./toyota/modals/modals";
import { getUscComparePdfLink } from "../utils/uscCompareUtils";
import { getUscExtraQueryBuilderOptions } from "../../shared-logic/utils/uscUtils";

const UsedCompareToyota = (): JSX.Element => {
    const commonSettings = useCompareUSCSelector((state) => state.commonSettings);

    useInitUsedCompare();
    useModalMap(aemToyotaCompareUscModalMap);

    const extraQueryBuilderOptions = getUscExtraQueryBuilderOptions(commonSettings);
    useStatus(
        commonSettings,
        "Compare V2",
        [{ links: [{ title: "Direct link to PDF", href: getUscComparePdfLink(commonSettings) }] }],
        [],
        extraQueryBuilderOptions,
    );

    return <AEMUsedCompareView />;
};

export default UsedCompareToyota;
