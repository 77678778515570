import styled from "styled-components";
import * as Button from "../../../../../common-deprecated/styles/v2/toyota/components/Button";
import { theme } from "../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

const CompareActions = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: ${theme.space[4]}px;
    padding-bottom: ${theme.space[4]}px;

    ${Button.ButtonPrimary},
    ${Button.ButtonSecondary} {
        max-width: 100%;
        margin: 0 0 ${theme.space[2]}px;
    }
`;

export default CompareActions;
